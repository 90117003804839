import { db } from './firebase';
import { 
  collection, 
  doc, 
  getDocs, 
  query, 
  orderBy, 
  serverTimestamp, 
  writeBatch,
  where, 
  setDoc 
 } from 'firebase/firestore';
import { ChatHistoryItem } from '../types';
import { Timestamp, DocumentData } from 'firebase/firestore';

export const STORAGE_KEY = 'gptbangla_chat_history';

export const clearLocalStorage = () => {
  localStorage.removeItem(STORAGE_KEY);
};

const convertTimestamp = (timestamp: unknown): Date => {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  }
  return new Date(timestamp);
};

const sanitizeData = (data: unknown): DocumentData => {
  if (Array.isArray(data)) {
    return data.map(item => sanitizeData(item));
  }
  if (data && typeof data === 'object') {
    const cleaned: Record<string, any> = {};
    for (const [key, value] of Object.entries(data)) {
      if (value !== undefined && value !== null) {
        cleaned[key] = sanitizeData(value);
      }
    }
    return cleaned;
  }
  return data;
};

export const saveChatHistory = async (userId: string | null, chatHistory: ChatHistoryItem[]) => {
  try {
    if (userId) {
      const userChatsRef = collection(db, `users/${userId}/chats`);
      const batch = writeBatch(db);
      
      chatHistory.forEach((chat) => {
        const chatRef = doc(userChatsRef, chat.id);
        const sanitizedChat = sanitizeData({
          ...chat,
          userId,
          updatedAt: serverTimestamp()
        });
        batch.set(chatRef, sanitizedChat);
      });
      
      await batch.commit();
    } else {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(chatHistory));
    }
  } catch (error) {
    console.error('Error saving chat history:', error);
  }
};

export const loadChatHistory = async (userId: string | null): Promise<ChatHistoryItem[]> => {
  try {
    if (userId) {
      // Get reference to the user's chats collection
      const userChatsRef = collection(db, `users/${userId}/chats`);

      // Query chats with proper ordering
      const q = query(
        userChatsRef,
        where('userId', '==', userId),
        orderBy('updatedAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      
      const chats = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title || 'New Chat',
          preview: data.preview || '',
          messages: Array.isArray(data.messages) ? data.messages.filter(Boolean) : [],
          updatedAt: data.updatedAt ? convertTimestamp(data.updatedAt) : new Date()
        };
      });
      
      return chats;
    }
    
    // If no userId, return empty array to start fresh
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : [];
  } catch (error) {
    console.error('Error loading chat history:', error);
    return [];
  }
};