import React, { useRef, useEffect } from 'react';
import { ChatMessage } from './ChatMessage';
import { Message } from '../types';
import { useScrollToBottom } from '../hooks/useScrollToBottom';
import { useTheme } from '../context/ThemeContext';
import { Loader2 } from 'lucide-react';

interface ChatContainerProps {
  messages: Message[];
  isGenerating?: boolean;
  model: string;
}

export function ChatContainer({ messages, isGenerating, model }: ChatContainerProps) {
  const { isDarkMode } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  const { handleScroll } = useScrollToBottom(messagesEndRef, [messages]);

  const bgColor = isDarkMode ? 'bg-[#343541]' : 'bg-white';

  return (
    <div 
      ref={containerRef}
      className={`
        flex-1 
        overflow-y-auto 
        ${bgColor} 
        transition-colors 
        duration-200
        mt-[60px]
        pb-[76px]
        px-2 
        md:px-4
        chat-container
      `}
      onScroll={() => {
        if (containerRef.current) {
          handleScroll(containerRef.current);
        }
      }}
      style={{ 
        overscrollBehavior: 'contain',
        WebkitOverflowScrolling: 'touch'
      }}
    >
      {messages.length === 0 && (
        <div className={`text-center mt-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Start a conversation...
        </div>
      )}
      {messages.map((message, index) => (
        <ChatMessage 
          key={index} 
          message={message} 
          isGenerating={isGenerating && index === messages.length - 1}
          model={model}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}