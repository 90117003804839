import React from 'react';
import { User } from 'lucide-react';
import { Message } from '../types';
import { useTheme } from '../context/ThemeContext';
import ReactMarkdown from 'react-markdown';
import { InlineMath, BlockMath } from 'react-katex';
import { Loader2 } from 'lucide-react';

interface ChatMessageProps {
  message: Message;
  isGenerating?: boolean;
  model?: string;
}

export function ChatMessage({ message, isGenerating, model = '' }: ChatMessageProps) {
  const { isDarkMode } = useTheme();
  const isUser = message.role === 'user';

  const messageBg = isDarkMode ? 'bg-[#343541]' : 'bg-white';
  const textColor = isDarkMode ? 'text-gray-100' : 'text-gray-800';
  const avatarBg = isDarkMode ? 'bg-gray-600' : 'bg-gray-200';
  const loaderColor = isDarkMode ? 'text-gray-200' : 'text-gray-800';
  const showThinking = model.startsWith('o1-');

  const renderContent = (content: string) => {
    // Split content by LaTeX delimiters
    const parts = content.split(/(\\\[.*?\\\]|\\\(.*?\\\))/s);
    
    return parts.map((part, index) => {
      // Handle block math
      if (part.startsWith('\\[') && part.endsWith('\\]')) {
        return (
          <div key={index} className={`my-4 ${textColor}`}>
            <BlockMath math={part.slice(2, -2).trim()} />
          </div>
        );
      }
      
      // Handle inline math
      if (part.startsWith('\\(') && part.endsWith('\\)')) {
        return (
          <span key={index} className={textColor}>
            <InlineMath math={part.slice(2, -2).trim()} />
          </span>
        );
      }
      
      // Handle regular text with markdown
      return (
        <ReactMarkdown
          key={index}
          className={`${textColor} text-base leading-7`}
          components={{
            h1: ({node, ...props}) => <h1 className="text-2xl font-bold my-4" {...props} />,
            h2: ({node, ...props}) => <h2 className="text-xl font-bold my-3" {...props} />,
            h3: ({node, ...props}) => <h3 className="text-lg font-bold my-2" {...props} />,
            p: ({node, ...props}) => <p className="my-2 text-base whitespace-pre-wrap" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc ml-6 my-2" {...props} />,
            ol: ({node, ...props}) => <ol className="list-decimal ml-6 my-2" {...props} />,
            li: ({node, ...props}) => <li className="my-1" {...props} />,
            code: ({node, inline, ...props}) => 
              inline ? 
                <code className={`px-1 rounded ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`} {...props} /> :
                <code className={`block p-3 rounded my-2 overflow-x-auto ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`} {...props} />,
            pre: ({node, ...props}) => <pre className="my-2" {...props} />,
            strong: ({node, ...props}) => <strong className="font-bold" {...props} />,
            em: ({node, ...props}) => <em className="italic" {...props} />,
            blockquote: ({node, ...props}) => (
              <blockquote className={`border-l-4 ${isDarkMode ? 'border-gray-600' : 'border-gray-300'} pl-4 my-2 italic`} {...props} />
            ),
            table: ({node, ...props}) => (
              <div className="overflow-x-auto my-4">
                <table className={`min-w-full divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`} {...props} />
              </div>
            ),
            th: ({node, ...props}) => (
              <th className={`px-4 py-2 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} font-semibold text-left`} {...props} />
            ),
            td: ({node, ...props}) => (
              <td className={`px-4 py-2 border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`} {...props} />
            )
          }}
        >
          {part}
        </ReactMarkdown>
      );
    });
  };

  return (
    <div className={`${messageBg} transition-colors duration-200 py-4 md:py-6`}>
      <div className="max-w-4xl mx-auto px-4">
        <div className={`flex items-start gap-4 md:gap-6 ${isUser ? 'flex-row-reverse' : ''}`}>
          <div className="flex-shrink-0 -mt-1">
            <div className={`w-8 h-8 rounded-sm ${avatarBg} flex items-center justify-center`}>
              {isUser ? (
                <User className={`h-5 w-5 ${isDarkMode ? 'text-white' : 'text-gray-700'}`} />
              ) : (
                <span className={isDarkMode ? 'text-white' : 'text-gray-700'}>B</span>
              )}
            </div>
          </div>
          
          <div className={`flex-1 min-w-0 ${isUser ? 'text-right' : ''} overflow-hidden`}>
            {isGenerating && message.role === 'assistant' && message.content === '' && showThinking && (
              <div className="flex items-center gap-2">
                <Loader2 className={`w-4 h-4 animate-spin ${loaderColor}`} />
                <span className={`text-sm ${loaderColor}`}>Thinking...</span>
              </div>
            )}
            {message.image && (
              <div className="mb-4">
                <img 
                  src={message.image} 
                  alt="Uploaded content"
                  className="max-w-full rounded-lg shadow-md"
                />
              </div>
            )}
            {renderContent(message.content)}
          </div>
        </div>
      </div>
    </div>
  );
}