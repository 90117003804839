import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { LogIn, LogOut, Loader2, AlertCircle } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

export function UserProfile() {
  const { user, signIn, signOut } = useAuth();
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSignIn = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await signIn();
    } catch (error) {
      setError('Failed to sign in with Google. Please try again.');
      console.error('Sign in error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      setError(null);
      await signOut();
    } catch (error) {
      setError('Failed to sign out. Please try again.');
      console.error('Sign out error:', error);
    }
  };

  const textColor = isDarkMode ? 'text-gray-200' : 'text-gray-700';
  const subTextColor = isDarkMode ? 'text-gray-400' : 'text-gray-500';

  return (
    <div className={`p-4 border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      {error && (
        <div className="mb-4 p-3 bg-red-900/50 border border-red-700 rounded-lg text-red-200 text-sm flex items-start gap-2">
          <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
          <span>{error}</span>
        </div>
      )}
      
      {user ? (
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            {user.photoURL && (
              <img
                src={user.photoURL}
                alt="Profile"
                className="w-8 h-8 rounded-full"
              />
            )}
            <span className={`font-medium ${textColor}`}>
              {user.displayName}
            </span>
          </div>
          <button
            onClick={handleSignOut}
            className={`p-2 ${subTextColor} hover:text-blue-500 transition-colors`}
            title="Sign Out"
          >
            <LogOut className="w-5 h-5" />
          </button>
        </div>
      ) : (
        <button
          onClick={handleSignIn}
          disabled={isLoading}
          className={`
            flex items-center justify-center space-x-2 w-full px-4 py-2 
            text-white bg-blue-600 hover:bg-blue-700 rounded-lg 
            transition-colors disabled:opacity-50 disabled:cursor-not-allowed
          `}
        >
          {isLoading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            <>
              <LogIn className="w-5 h-5" />
              <span>Sign in with Google</span>
            </>
          )}
        </button>
      )}
    </div>
  );
}