import { useEffect, useRef, RefObject } from 'react';

export function useScrollToBottom(
  containerRef: RefObject<HTMLElement>,
  dependencies: any[],
  smooth: boolean = true
) {
  const shouldScrollRef = useRef(true);
  const isScrollingRef = useRef(false);

  useEffect(() => {
    if (!containerRef.current || !shouldScrollRef.current) return;

    const container = containerRef.current;
    const scrollOptions: ScrollIntoViewOptions = {
      behavior: smooth ? 'smooth' : 'auto',
      block: 'end',
    };

    // Add a small delay to ensure content is rendered
    setTimeout(() => {
      if (!isScrollingRef.current) {
        container.scrollIntoView(scrollOptions);
      }
    }, 100);
  }, dependencies);

  const handleScroll = (container: HTMLElement) => {
    const { scrollTop, scrollHeight, clientHeight } = container;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    
    // Only auto-scroll if we're near the bottom
    shouldScrollRef.current = distanceFromBottom < 100;
    
    // Track manual scrolling
    if (distanceFromBottom > 100) {
      isScrollingRef.current = true;
    } else {
      isScrollingRef.current = false;
    }
  };

  return {
    handleScroll,
    shouldScroll: shouldScrollRef.current,
  };
}