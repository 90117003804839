import { Message, ChatHistoryItem } from '../types';

export const generateChatTitle = (content: string): string => {
  if (!content) return 'New Chat';
  
  // Remove any special characters and trim
  const cleaned = content.replace(/[^\w\s]/gi, ' ').trim();
  
  // Get first few words (max 6 words)
  const words = cleaned.split(' ').slice(0, 6);
  
  // Create title
  let title = words.join(' ');
  
  // If title is too long, truncate it
  if (title.length > 50) {
    title = title.substring(0, 47) + '...';
  }
  
  return title || 'New Chat';
};

export const generatePreview = (content: string): string => {
  if (!content) return '';
  const preview = content.slice(0, 50);
  return preview + (content.length > 50 ? '...' : '');
};

export const createNewChat = (): ChatHistoryItem => {
  return {
    id: Date.now().toString(),
    title: 'New Chat',
    preview: '',
    messages: [],
    updatedAt: new Date()
  };
};

export const updateChatPreview = (messages: Message[]): string => {
  const firstUserMessage = messages.find(m => m.role === 'user')?.content || '';
  return generatePreview(firstUserMessage);
};