import { initializeApp } from 'firebase/app';
import { getFirestore, persistentLocalCache, persistentSingleTabManager, initializeFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD7iuJQWwju-3RlCqIBzZXHVZasm4gcyKA",
  authDomain: "gptbangla-676f0.firebaseapp.com",
  projectId: "gptbangla-676f0",
  storageBucket: "gptbangla-676f0.appspot.com",
  messagingSenderId: "420931539580",
  appId: "1:420931539580:web:e9adedf992b82faf94aff9",
  measurementId: "G-3JVVE53P7H"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore with persistence
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentSingleTabManager(),
    cacheSizeBytes: 100 * 1024 * 1024 // 100MB cache size
  })
});

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: 'select_account'
});

export { auth, db, provider };