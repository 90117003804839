import React, { useState, useRef } from 'react';
import { Send, Image, Square, Wand2 } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

interface ChatInputProps {
  onSendMessage: (message: string, image?: File) => void;
  onGenerateImage: (prompt: string) => void;
  isLoading: boolean;
  isEnglishMode: boolean;
}

export function ChatInput({ onSendMessage, onGenerateImage, isLoading, isEnglishMode }: ChatInputProps) {
  const { isDarkMode } = useTheme();
  const [input, setInput] = useState('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isImageGenMode, setIsImageGenMode] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim() || selectedImage) {
      if (isImageGenMode) {
        onGenerateImage(input);
      } else {
        onSendMessage(input, selectedImage || undefined);
      }
      setInput('');
      setSelectedImage(null);
      setIsImageGenMode(false);
    }
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif')) {
      setSelectedImage(file);
      setIsImageGenMode(false);
    }
  };

  const bgColor = isDarkMode ? 'bg-[#343541]' : 'bg-white';
  const borderColor = isDarkMode ? 'border-gray-700' : 'border-gray-200';
  const textColor = isDarkMode ? 'text-white' : 'text-gray-900';
  const placeholderColor = isDarkMode ? 'placeholder-gray-400' : 'placeholder-gray-500';

  return (
    <div className={`
      fixed 
      bottom-0 
      left-0 
      right-0 
      ${bgColor} 
      border-t 
      ${borderColor} 
      p-4 
      pb-[calc(1rem+env(safe-area-inset-bottom))]
      w-full 
      transition-colors 
      duration-200
      chat-input
      z-10
    `}>
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto relative">
        <div className="relative flex items-center">
          <div className="absolute left-3 flex items-center space-x-2">
            <button
              type="button"
              onClick={() => {
                setIsImageGenMode(false);
                fileInputRef.current?.click();
              }}
              className={`text-gray-500 hover:text-blue-500 transition-colors ${isImageGenMode ? 'opacity-50' : ''}`}
              disabled={isLoading}
              title={isEnglishMode ? "Upload image" : "ছবি আপলোড করুন"}
            >
              <Image className="h-5 w-5" />
            </button>
            <button
              type="button"
              onClick={() => {
                setIsImageGenMode(!isImageGenMode);
                setSelectedImage(null);
              }}
              className={`text-gray-500 hover:text-blue-500 transition-colors ${selectedImage ? 'opacity-50' : ''}`}
              disabled={isLoading}
              title={isEnglishMode ? "Generate image" : "ছবি তৈরি করুন"}
            >
              <Wand2 className={`h-5 w-5 ${isImageGenMode ? 'text-blue-500' : ''}`} />
            </button>
          </div>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={isImageGenMode 
              ? (isEnglishMode ? "Describe the image you want to generate..." : "যে ছবিটি তৈরি করতে চান তার বর্ণনা দিন...")
              : (isEnglishMode ? "Type your message..." : "আপনার মেসেজ লিখুন...")
            }
            className={`w-full rounded-lg ${bgColor} border ${borderColor} pl-20 pr-12 py-3 ${textColor} ${placeholderColor} focus:outline-none focus:ring-2 focus:ring-blue-500 text-base shadow-sm transition-all`}
            disabled={isLoading}
          />
          <input
            ref={fileInputRef}
            type="file"
            accept="image/jpeg,image/png,image/gif"
            onChange={handleImageSelect}
            className="hidden"
          />
          <button
            type="submit"
            disabled={isLoading || (!input.trim() && !selectedImage)}
            className="absolute right-3 text-gray-500 hover:text-blue-500 disabled:opacity-50 disabled:hover:text-gray-500 transition-colors"
          >
            {isLoading ? (
              <Square className="h-5 w-5 animate-spin" />
            ) : (
              <Send className="h-5 w-5" />
            )}
          </button>
        </div>
        {selectedImage && (
          <div className="mt-2 flex items-center gap-2">
            <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {selectedImage.name}
            </span>
            <button
              type="button"
              onClick={() => setSelectedImage(null)}
              className="text-xs text-red-400 hover:text-red-500 transition-colors"
            >
              Remove
            </button>
          </div>
        )}
        {isImageGenMode && (
          <div className="mt-2">
            <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              {isEnglishMode 
                ? "Image generation mode active - Enter a description to create an image"
                : "ছবি তৈরির মোড সক্রিয় - একটি ছবি তৈরি করতে বর্ণনা লিখুন"}
            </span>
          </div>
        )}
      </form>
    </div>
  );
}