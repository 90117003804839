import React from 'react';
import { Plus, MessageSquare, X } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import { UserProfile } from './UserProfile';
import { ChatHistoryItem } from '../types';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  onNewChat: () => void;
  chatHistory: ChatHistoryItem[];
  onSelectChat: (chatId: string) => void;
  currentChatId: string;
}

export function Sidebar({ isOpen, onClose, onNewChat, chatHistory, onSelectChat, currentChatId }: SidebarProps) {
  const { isDarkMode } = useTheme();
  const bgColor = isDarkMode ? 'bg-gray-900' : 'bg-white';
  const textColor = isDarkMode ? 'text-white' : 'text-gray-900';
  const borderColor = isDarkMode ? 'border-gray-700' : 'border-gray-200';
  const hoverBg = isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100';

  return (
    <>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={onClose}
        />
      )}
      
      <aside className={`
        fixed
        inset-y-0 left-0
        w-[260px] ${bgColor}
        transform transition-transform duration-200 ease-in-out
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        z-50
        flex flex-col
        h-[100dvh]
        md:translate-x-0
        md:z-30
        md:border-r ${borderColor}
        ${isOpen ? '' : 'md:hidden'}
      `}>
        <div className="flex items-center justify-between p-3 md:p-4">
          <button 
            onClick={onNewChat}
            className={`flex items-center space-x-2 ${textColor} ${hoverBg} rounded-lg px-3 py-2 w-full text-sm md:text-base border ${borderColor}`}
          >
            <Plus className="h-4 w-4 md:h-5 md:w-5" />
            <span>New Conversation</span>
          </button>
          <button 
            onClick={onClose}
            className={`md:hidden ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'} p-1`}
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-2">
          <div className="space-y-2">
            {chatHistory.map((chat) => (
              <button
                key={chat.id}
                onClick={() => onSelectChat(chat.id)}
                className={`
                  flex items-center space-x-3 
                  ${textColor} ${hoverBg} 
                  rounded-lg px-3 py-2 
                  w-full text-left text-sm md:text-base
                  ${currentChatId === chat.id ? (isDarkMode ? 'bg-gray-800' : 'bg-gray-100') : ''}
                  transition-colors duration-200
                `}
              >
                <MessageSquare className="h-4 w-4 flex-shrink-0" />
                <span className="truncate">{chat.title || chat.preview || 'New Conversation'}</span>
              </button>
            ))}
          </div>
        </div>

        <UserProfile />
      </aside>
    </>
  );
}