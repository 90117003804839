import OpenAI from 'openai';

const validateApiKey = () => {
  const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
  if (!apiKey || apiKey === 'your_openai_api_key_here') {
    throw new Error('Please set your OpenAI API key');
  }
  return apiKey;
};

const openai = new OpenAI({
  apiKey: validateApiKey(),
  dangerouslyAllowBrowser: true
});

export async function generateImage(prompt: string): Promise<string> {
  try {
    validateApiKey();
    
    const response = await openai.images.generate({
      model: "dall-e-3",
      prompt,
      n: 1,
      size: "1024x1024",
      quality: "standard",
      style: "vivid"
    });

    return response.data[0].url;
  } catch (error: any) {
    console.error('Image Generation Error:', error.message);
    throw new Error(error.message);
  }
}

export async function getChatCompletion(
  messages: { role: 'user' | 'assistant' | 'system'; content: string }[],
  model: string = 'gpt-4o',
  onStream?: (chunk: string) => void,
  signal?: AbortSignal
) {
  try {
    validateApiKey();

    // Filter out system messages for models that don't support them
    const filteredMessages = messages.filter(msg => {
      if (model.startsWith('o1-')) {
        return msg.role !== 'system';
      }
      return true;
    });
    
    // Add language instruction as part of the first message for models that don't support system messages
    if (model.startsWith('o1-') && messages[0]?.role === 'system') {
      const systemInstruction = messages[0].content;
      const firstMessage = filteredMessages[0];
      if (firstMessage) {
        filteredMessages[0] = {
          ...firstMessage,
          content: `${systemInstruction}\n\n${firstMessage.content}`
        };
      }
    }

    const streamConfig: any = {
      messages: filteredMessages,
      model: model,
      stream: true,
      temperature: model.startsWith('o1-') ? undefined : 0.7,
      presence_penalty: model.startsWith('o1-') ? undefined : 0.6,
      frequency_penalty: model.startsWith('o1-') ? undefined : 0.6,
      top_p: model.startsWith('o1-') ? undefined : 0.9
    };

    const stream = await openai.chat.completions.create(streamConfig);

    let fullResponse = '';

    for await (const chunk of stream) {
      if (signal?.aborted) {
        throw new Error('Request aborted');
      }
      
      const content = chunk.choices[0]?.delta?.content || '';
      if (content) {
        fullResponse += content;
        onStream?.(content);
      }
    }

    return { content: fullResponse };
  } catch (error: any) {
    if (error.name === 'AbortError' || error.message === 'Request aborted') {
      throw error;
    }
    
    console.error('OpenAI API Error:', error.message);
    
    if (error.error?.type === 'invalid_request_error') {
      throw new Error('Invalid request. Please make your question shorter or try again.');
    }
    
    if (error.status === 429) {
      throw new Error('Rate limit exceeded. Please wait a moment and try again.');
    }
    
    if (error.status === 401) {
      throw new Error('Invalid API key. Please check your OpenAI API key.');
    }
    
    throw new Error(error.message || 'An error occurred. Please try again.');
  }
}