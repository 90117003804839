import React, { useState, useEffect, useRef } from 'react';
import { ChatHistoryItem, Message } from '../types';
import { createNewChat, updateChatPreview, generateChatTitle } from '../utils/chatUtils';
import { saveChatHistory, loadChatHistory, STORAGE_KEY, clearLocalStorage } from '../services/chatService';

export const useChatHistory = (userId: string | null) => {
  const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([]);
  const [currentChatId, setCurrentChatId] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const initializedRef = useRef(false);

  useEffect(() => {
    const loadHistory = async () => {
      try {
        const chats = await loadChatHistory(userId);
        setChatHistory(chats);
        if (chats.length > 0) {
          setCurrentChatId(chats[0].id);
          setMessages(chats[0].messages);
        } else {
          const newChat = createNewChat();
          setChatHistory([newChat]);
          setCurrentChatId(newChat.id);
          setMessages([]);
        }
      }
      catch (error) {
        console.error('Error loading chat history:', error);
        const newChat = createNewChat();
        setChatHistory([newChat]);
        setCurrentChatId(newChat.id);
        setMessages([]);
      }
    };
    
    if (!initializedRef.current || userId !== undefined) {
      loadHistory();
      initializedRef.current = true;
    }
  }, [userId]);

  useEffect(() => {
    if (userId === null && initializedRef.current) {
      const newChat = createNewChat();
      setChatHistory([newChat]);
      setCurrentChatId(newChat.id);
      setMessages([]);
    }
  }, [userId]);

  const handleNewChat = () => {
    const newChat = createNewChat();
    setCurrentChatId(newChat.id);
    setMessages([]);
    
    const updatedHistory = [newChat, ...chatHistory];
    setChatHistory(updatedHistory);
    saveChatHistory(userId, updatedHistory);
  };

  const updateChatHistory = async (newMessages: Message[]) => {
    if (newMessages.length === 0) return;

    const preview = updateChatPreview(newMessages, userId);
    const title = generateChatTitle(newMessages[0]?.content || '');
    
    const updatedChat: ChatHistoryItem = {
      id: currentChatId,
      title,
      preview,
      messages: newMessages,
      updatedAt: new Date()
    };

    const newHistory = chatHistory.map(chat => 
      chat.id === currentChatId ? updatedChat : chat
    );
    
    if (!newHistory.find(chat => chat.id === currentChatId)) {
      newHistory.unshift(updatedChat);
    }
    
    setChatHistory(newHistory);
    saveChatHistory(userId, newHistory);
  };

  const loadChat = (chatId: string) => {
    const chat = chatHistory.find(c => c.id === chatId);
    if (chat) {
      setCurrentChatId(chatId);
      setMessages(chat.messages);
    }
  };

  return {
    chatHistory,
    currentChatId,
    messages,
    setMessages,
    handleNewChat,
    updateChatHistory,
    loadChat
  };
};