import React, { useRef } from 'react';
import { Sidebar } from './components/Sidebar';
import { Header } from './components/Header';
import { ChatContainer } from './components/ChatContainer';
import { ChatInput } from './components/ChatInput';
import { ChatState, Message } from './types';
import { getChatCompletion, generateImage } from './services/openai';
import { useTheme } from './context/ThemeContext';
import { useAuth } from './context/AuthContext';
import { useChatHistory } from './hooks/useChatHistory';

export default function App() {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const abortControllerRef = useRef<AbortController | null>(null);
  const {
    chatHistory,
    currentChatId,
    messages,
    setMessages,
    handleNewChat,
    updateChatHistory,
    loadChat
  } = useChatHistory(user?.uid || null);
  const [state, setState] = React.useState<ChatState>({
    messages: [],
    isLoading: false,
    model: 'gpt-4o',
    isEnglishMode: true,
    isDarkMode: false
  });

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  

  const handleGenerateImage = async (prompt: string) => {
    if (!prompt.trim() || state.isLoading) return;

    const userMessage: Message = {
      role: 'user',
      content: `Generate image: ${prompt}`
    };

    setState(prev => ({
      ...prev,
      messages: [...prev.messages, userMessage],
      isLoading: true
    }));

    try {
      const imageUrl = await generateImage(prompt);
      
      const assistantMessage: Message = {
        role: 'assistant',
        content: state.isEnglishMode 
          ? 'Here is your generated image:'
          : 'এখানে আপনার তৈরি করা ছবি:',
        image: imageUrl
      };

      const newMessages = [...messages, userMessage, assistantMessage];
      setMessages(newMessages);
      setState(prev => ({ ...prev, isLoading: false }));
      await updateChatHistory(newMessages);
    } catch (error) {
      console.error('Error generating image:', error);
      const errorMessage = state.isEnglishMode
        ? "Sorry, I couldn't generate the image. Please try again."
        : "দুঃখিত, ছবি তৈরি করা সম্ভব হয়নি। অনুগ্রহ করে আবার চেষ্টা করুন।";
      
      const newMessages = [...messages, userMessage, { role: 'assistant', content: errorMessage }];
      setMessages(newMessages);
      setState(prev => ({ ...prev, isLoading: false }));
      await updateChatHistory(newMessages);
    }
  };

  const handleSendMessage = async (content: string, image?: File) => {
    if ((!content.trim() && !image) || state.isLoading) return;

    // Reset any previous errors
    setState(prev => ({ ...prev, error: null }));

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setState(prev => ({ ...prev, isLoading: false }));
      return;
    }

    abortControllerRef.current = new AbortController();

    let messageContent = content;
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      await new Promise<void>((resolve) => {
        reader.onloadend = () => {
          messageContent = `${content}\n\n[Image: ${image.name}]`;
          resolve();
        };
      });
    }

    const userMessage: Message = { 
      role: 'user', 
      content: messageContent,
      image: image ? URL.createObjectURL(image) : undefined
    };
    
    const newMessagesWithUser = [...messages, userMessage];
    setMessages(newMessagesWithUser);
    setState(prev => ({ ...prev, isLoading: true }));
    await updateChatHistory(newMessagesWithUser);

    try {
      const systemPrompt = {
        role: 'system' as const,
        content: state.isEnglishMode
          ? `You are an advanced AI assistant that excels at understanding context and providing comprehensive responses in English.`
          : `You are an advanced AI assistant. Respond in fluent Bengali (Bangla) using Unicode.`
      };

      const chatMessages = [
        systemPrompt,
        ...messages.map(msg => ({
          role: msg.role,
          content: msg.content
        })),
        { role: 'user' as const, content: messageContent }
      ];

      const tempMessage: Message = {
        role: 'assistant',
        content: ''
      };

      const newMessagesWithTemp = [...newMessagesWithUser, tempMessage];
      setMessages(newMessagesWithTemp);

      await getChatCompletion(
        chatMessages, 
        state.model,
        (chunk) => {
          setMessages(prev => 
            prev.map((msg, i) => 
              i === prev.length - 1
                ? { ...msg, content: msg.content + chunk }
                : msg
            )
          );
        },
        abortControllerRef.current.signal
      );

      setState(prev => ({ ...prev, isLoading: false }));
      await updateChatHistory(newMessagesWithTemp);
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        setState(prev => ({ ...prev, isLoading: false }));
        return;
      }

      const errMsg = error instanceof Error ? error.message : 'An unknown error occurred';
      const displayMessage = state.isEnglishMode
        ? errMsg
        : `দুঃখিত, একটি সমস্যা হয়েছে: ${errMsg}`;
      
      const newMessages = [...messages, { role: 'assistant', content: displayMessage }];
      setMessages(newMessages);
      setState(prev => ({ ...prev, isLoading: false }));
      
      // Only update chat history for non-API key errors
      if (!errMsg.includes('API key')) {
        await updateChatHistory(newMessages);
      }
    } finally {
      abortControllerRef.current = null;
    }
  };

  const bgColor = isDarkMode ? 'bg-[#343541]' : 'bg-white';

  return (
    <div className="flex min-h-screen">
      <Sidebar 
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        onNewChat={handleNewChat}
        chatHistory={chatHistory}
        onSelectChat={loadChat}
        currentChatId={currentChatId}
      />
      
      <div className={`flex-1 flex flex-col min-h-screen ${bgColor} transition-all duration-200`}>
        <Header
          model={state.model}
          setModel={(model) => setState(prev => ({ ...prev, model }))}
          isEnglishMode={state.isEnglishMode}
          setIsEnglishMode={(mode) => setState(prev => ({ ...prev, isEnglishMode: mode }))}
          onMenuClick={() => setIsSidebarOpen(!isSidebarOpen)}
          isSidebarOpen={isSidebarOpen}
        />
        
        <div className={`flex-1 transition-all duration-200 ${isSidebarOpen ? 'md:ml-[260px]' : ''}`}>
          <ChatContainer 
            messages={messages} 
            isGenerating={state.isLoading}
            model={state.model} 
          />
        </div>

        <div className={`transition-all duration-200 ${isSidebarOpen ? 'md:ml-[260px]' : ''}`}>
          <ChatInput
            onSendMessage={handleSendMessage}
            onGenerateImage={handleGenerateImage}
            isLoading={state.isLoading}
            isEnglishMode={state.isEnglishMode}
          />
        </div>
      </div>
    </div>
  );
}