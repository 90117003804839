import React from 'react';
import { Menu, MessageSquare, Sun, Moon } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';

interface HeaderProps {
  model: string;
  setModel: (model: string) => void;
  isEnglishMode: boolean;
  setIsEnglishMode: (mode: boolean) => void;
  onMenuClick: () => void;
  isSidebarOpen: boolean;
}

const VALID_MODELS = {
  'gpt-4o': 'GPT-4o Latest',
  'gpt-4o-mini': 'GPT-4o Mini',
  'o1-preview': 'o1 (128K tokens)',
  'o1-mini': 'o1-Mini (128K tokens)'
} as const;

export function Header({ model, setModel, isEnglishMode, setIsEnglishMode, onMenuClick, isSidebarOpen }: HeaderProps) {
  const { isDarkMode, toggleTheme } = useTheme();
  
  const bgColor = isDarkMode ? 'bg-gray-900' : 'bg-white';
  const borderColor = isDarkMode ? 'border-gray-700' : 'border-gray-200';
  const textColor = isDarkMode ? 'text-white' : 'text-gray-900';
  const iconColor = isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900';

  return (
    <header className={`
      ${bgColor} 
      border-b 
      ${borderColor} 
      py-2 
      px-3 
      md:py-3 
      md:px-4 
      transition-colors 
      duration-200 
      fixed 
      top-0 
      left-0 
      right-0 
      z-20
      ${isSidebarOpen ? 'md:pl-[276px]' : ''}
    `}>
      <div className="max-w-7xl mx-auto flex items-center justify-between h-12">
        <div className="flex items-center space-x-2 md:space-x-4">
          <button
            onClick={onMenuClick}
            className={`${iconColor} p-1`}
          >
            <Menu className="h-5 w-5 md:h-6 md:w-6" />
          </button>
          <div className="flex items-center space-x-2">
            <MessageSquare className={`h-5 w-5 md:h-6 md:w-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} />
            <h1 className={`text-lg md:text-xl font-bold ${textColor}`}>GPTBangla</h1>
          </div>
        </div>
        
        <div className="flex items-center space-x-2 md:space-x-4">
          <select
            value={model}
            onChange={(e) => setModel(e.target.value)}
            className={`${bgColor} ${textColor} border ${borderColor} rounded-md px-2 py-1 md:px-3 md:py-1.5 text-xs md:text-sm focus:ring-2 focus:ring-blue-500 max-w-[120px] md:max-w-none transition-colors duration-200`}
          >
            {Object.entries(VALID_MODELS).map(([id, name]) => (
              <option key={id} value={id}>{name}</option>
            ))}
          </select>
          
          <div className="flex items-center space-x-2">
            <span className={`text-xs md:text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {isEnglishMode ? 'EN' : 'বাং'}
            </span>
            <button
              onClick={() => setIsEnglishMode(!isEnglishMode)}
              className={`relative inline-flex h-5 w-9 md:h-6 md:w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                isEnglishMode ? 'bg-blue-500' : 'bg-gray-400'
              }`}
            >
              <span
                className={`inline-block h-3 w-3 md:h-4 md:w-4 transform rounded-full bg-white transition-transform ${
                  isEnglishMode ? 'translate-x-5 md:translate-x-6' : 'translate-x-1'
                }`}
              />
            </button>
            <button
              onClick={toggleTheme}
              className={`p-1 rounded-lg ${iconColor}`}
            >
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}